@import url("./icons.css");
html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: 'Helvetica Neue', Helvetica, arial, sans-serif;
	font-weight: 400;
	color: #234766;
	/* color: #000; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
}


*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
}
body {
	overflow-x: hidden;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 1px;
}



div{
	display: block;
}
section {
	padding: 1rem;
	font-family: 'Courier New',Courier,monospace;
}

p {
	font-size: 1.25rem;
}
a, a.active, a:active, a:focus, a:hover {
/* 	color: #09c; */
	color: #1d824c;
	outline: 0;
	font-weight: 600;
}


@media (min-width: 1100px) {
	body {
		margin: 5em auto;
		max-width: 1000px;
	}

	p {
		font-size: 1.75rem;
 	}
}


#app {
	max-width: 800px;
	margin: 0 auto;
}
