@font-face {
	font-family: 'icomoon';
	src:  url('fonts/icomoon.eot?abi72a');
	src:  url('fonts/icomoon.eot?abi72a#iefix') format('embedded-opentype'),
	  url('fonts/icomoon.ttf?abi72a') format('truetype'),
	  url('fonts/icomoon.woff?abi72a') format('woff'),
	  url('fonts/icomoon.svg?abi72a#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
 }
 
 [class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
 
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
 }
 
 .icon-chevron-left:before {
	content: "\e900";
 }
 .icon-chevron-right:before {
	content: "\e901";
 }
 .icon-external-link:before {
	content: "\e902";
 }
 .icon-close:before {
	content: "\e903";
 }
 